import { IconSVGProps } from '../types'

export const StarSmileIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00004 0.991058L11.9431 5.29708L16.9484 6.76584L13.7622 10.8957L13.9119 16.1091L9.00004 14.3555L4.08813 16.1091L4.23786 10.8957L1.0517 6.76584L6.05693 5.29708L9.00004 0.991058ZM7.60718 8.99999H6.21432C6.21431 9.72675 6.49832 10.4247 7.00575 10.945C7.51317 11.4653 8.20384 11.7667 8.93037 11.7849C9.65691 11.803 10.3618 11.5366 10.8946 11.0423C11.4274 10.548 11.7459 9.86513 11.7823 9.13927L11.7858 8.99999H10.3929C10.3945 9.36142 10.2556 9.70933 10.0054 9.97022C9.75526 10.2311 9.4135 10.3845 9.05231 10.3981C8.69113 10.4117 8.33882 10.2843 8.0698 10.0429C7.80079 9.8015 7.63615 9.46499 7.61066 9.10445L7.60718 8.99999Z" />
  </svg>
)
