import { trackEvent } from 'segment_analytics/events'

const defaultEventProps = {
  platform: 'web',
}

const start = () => {
  return trackEvent('eoy_wrap_start', defaultEventProps)
}

const modalViewed = () => {
  return trackEvent('eoy_wrap_modal_viewed', defaultEventProps)
}

const finishedListDeeplink = () => {
  return trackEvent('eoy_wrap_finished_list_deeplink', defaultEventProps)
}

const polaroidPreSetup = () => {
  return trackEvent('eoy_wrap_polaroid_pre_setup', defaultEventProps)
}

const polaroidSetupSkip = () => {
  return trackEvent('eoy_wrap_polaroid_setup_skip', defaultEventProps)
}

const polaroidPreSetupSkip = () => {
  return trackEvent('eoy_wrap_polaroid_pre_setup_skip', defaultEventProps)
}

const polaroidSetup = ({ ...props }: { seasons: number }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_polaroid_setup', properties)
}

const seasonLoading = () => {
  return trackEvent('eoy_wrap_season_loading', defaultEventProps)
}

const polaroidSetupDone = () => {
  return trackEvent('eoy_wrap_polaroid_setup_done', defaultEventProps)
}

const superlativesLoading = () => {
  return trackEvent('eoy_wrap_superlatives_loading', defaultEventProps)
}

const percentileLoading = () => {
  return trackEvent('eoy_wrap_percentile_loading', defaultEventProps)
}

const summaryLoading = () => {
  return trackEvent('eoy_wrap_summary_loading', defaultEventProps)
}

const firstSeason = ({
  ...props
}: {
  season: string
  numBooks: number
  polaroid: string
  season_prompt: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_first_season', properties)
}

const season = ({
  ...props
}: {
  season: string
  numBooks: number
  polaroid: string
  season_prompt: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_season', properties)
}

const lastSeason = ({
  ...props
}: {
  season: string
  numBooks: number
  polaroid: string
  season_prompt: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_last_season', properties)
}

const themePicker = () => {
  return trackEvent('eoy_wrap_theme_picker', defaultEventProps)
}

const themeEdited = ({ ...props }: { theme: string }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_theme_edited', properties)
}

const igShareButton = ({ ...props }: { starting_from: string }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('wrap_ig_share_button', properties)
}

const tiktokShareButton = ({ ...props }: { starting_from: string }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('wrap_tiktok_share_button', properties)
}

const generalShareButton = ({ ...props }: { starting_from: string }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('wrap_general_shared', properties)
}

const igShared = () => {
  return trackEvent('wrap_ig_shared', defaultEventProps)
}

const tiktokShared = () => {
  return trackEvent('wrap_tiktok_shared', defaultEventProps)
}

const hotTakes = () => {
  return trackEvent('eoy_wrap_hot_takes', defaultEventProps)
}

const mostRead = () => {
  return trackEvent('eoy_wrap_most_read', defaultEventProps)
}

const leastRead = () => {
  return trackEvent('eoy_wrap_least_read', defaultEventProps)
}

const streakPercentile = ({ ...props }: { percentile: string }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_streak_percentile', properties)
}

const genrePercentile = ({
  ...props
}: {
  percentile: string
  genre: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_genre_percentile', properties)
}

const wrapSummary = ({ ...props }: { goal: boolean }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('eoy_wrap_summary', properties)
}

const nextYear = () => {
  return trackEvent('eoy_wrap_next_year', defaultEventProps)
}

const wtrList = () => {
  return trackEvent('eoy_wrap_wtr_list', defaultEventProps)
}

const shareSelection = () => {
  return trackEvent('eoy_wrap_share_selection', defaultEventProps)
}

const replayed = () => {
  return trackEvent('eoy_wrap_replayed', defaultEventProps)
}

const wrapClose = () => {
  return trackEvent('eoy_wrap_close', defaultEventProps)
}

const wrapShareSelectionBack = () => {
  return trackEvent('eoy_wrap_share_selection_back', defaultEventProps)
}

const modalZeroBooksViewed = () => {
  return trackEvent('eoy_wrap_modal_0_books_viewed', defaultEventProps)
}

export const eoyWrap = {
  start,
  modalViewed,
  finishedListDeeplink,
  polaroidPreSetup,
  polaroidSetupSkip,
  polaroidPreSetupSkip,
  polaroidSetup,
  seasonLoading,
  polaroidSetupDone,
  superlativesLoading,
  percentileLoading,
  summaryLoading,
  firstSeason,
  season,
  lastSeason,
  themePicker,
  themeEdited,
  igShareButton,
  tiktokShareButton,
  generalShareButton,
  igShared,
  tiktokShared,
  hotTakes,
  mostRead,
  leastRead,
  streakPercentile,
  genrePercentile,
  wrapSummary,
  nextYear,
  wtrList,
  shareSelection,
  replayed,
  wrapClose,
  wrapShareSelectionBack,
  modalZeroBooksViewed,
}
