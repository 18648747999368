import { lazyWithRetry } from '../../utils'
import { AppRouteProps } from '../types'
import BookReviewPage from '../../app/social/pages/book_review_page/BookReviewPage'
import QuoteViewPage from '../../app/social/pages/quote_view_page/QuoteViewPage'
import { routes } from '../../app/social/routes'
import InviteWelcomePage from '../../app/social/pages/invite_welcome_page/InviteWelcomePage'
import InvitePageAB from '../../app/social/pages/invite_page/InvitePageAB'

const BookListDetail = lazyWithRetry(
  () => import('../../app/social/pages/book_list_detail/BookListDetail')
)

const UserPostDetail = lazyWithRetry(
  () => import('../../app/social/pages/user_post_detail/UserPostDetail')
)

const SharePage = lazyWithRetry(
  () => import('../../app/social/pages/share_page/SharePage')
)

const DragonQuizPage = lazyWithRetry(
  () => import('../../app/social/pages/dragon_quiz_page/DragonQuizPage')
)

const ChatBotPage = lazyWithRetry(
  () => import('../../app/social/pages/chat_bot_page/ChatBotPage')
)

const StatsPage = lazyWithRetry(
  () => import('../../app/social/pages/stats_page/StatsPage')
)

const ReadingNookPage = lazyWithRetry(
  () => import('../../app/social/pages/reading_nook_page/ReadingNookPage')
)

export const socialRoutes: AppRouteProps[] = [
  {
    path: `${routes.bookList}/:id`,
    element: <BookListDetail />,
    footer: { hidden: true },
  },
  {
    path: `${routes.review}/:id`,
    element: <BookReviewPage />,
    footer: { hidden: true },
  },
  {
    path: `${routes.quote}/:id`,
    element: <QuoteViewPage />,
    footer: { hidden: true },
  },
  {
    path: routes.invite,
    element: <InvitePageAB />,
    header: { hidden: true },
  },
  {
    path: routes.inviteWelcome,
    element: <InviteWelcomePage />,
    header: {
      fillSpace: false,
    },
  },
  {
    path: `${routes.post}/:id`,
    element: <UserPostDetail />,
  },
  {
    path: `/:type/:id/share`,
    element: <SharePage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: routes.dragonQuiz,
    element: <DragonQuizPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: routes.chatbot,
    element: <ChatBotPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: routes.chatbotPreview,
    element: <ChatBotPage preview />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  // Test page for fanart generation
  {
    path: routes.fanart,
    element: <ChatBotPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  // Test page for fanart generation
  {
    path: routes.scoutCkg,
    element: <ChatBotPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: routes.stats,
    element: <StatsPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: `${routes.stats}/:id`,
    element: <StatsPage />,
    footer: { hidden: true },
    header: { hidden: true },
  },
  {
    path: `${routes.readingNook}/:id?`,
    element: <ReadingNookPage />,
    guarded: true,
    deprecated: true,
    footer: { hidden: true },
  },
]
