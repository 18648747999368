import { Suspense, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { injectGlobal, useTheme } from '@fable/theme'
import WebFont from 'webfontloader'
import {
  useTypedSelector,
  useActions,
  useAuthProviderRedirect,
  useLoadCart,
  useCacheOwnedBooks,
  useProfileRedirect,
  useLoadFeatureFlags,
  useVertExpEnabled,
  useLoadAnalytics,
} from 'hooks'
import {
  APP_FONTS,
  CAREER_CLUB_JOIN_KEY,
  GEO_IP_COUNTRY_SET,
} from '../constants'
import { getCookie } from '../utils'
import FooterLegacy from '../components/footer_legacy'
import { Modal } from '../components/modal/Modal'
import { Notification } from '../components/notification/Notification'
import Toast from '../components/toast/Toast'
import Loader, { LoaderType } from '../components/loader'
import { VideoLightbox } from '../components/VideoLightbox'
import { Subheader } from '../components/subheader/Subheader'
import Header from '../components/header'
import AppRoutes from '../routes/AppRoutes'
import { useConsent } from 'services/onetrust'
import { useSingular } from 'services/singular'

// GEO IP BLOCK
import GeoBlockErrorPage from '../geo_block/GeoBlockErrorPage'

// Suspense/Lazy loading
import SuspenseWithErrorBoundary from '../components/suspense_with_error_boundary/SuspenseWithErrorBoundary'
import Footer from 'components/footer'
import { TikTokOverlay } from 'components/tiktok_overlay/TikTokOverlay'
import { useDeviceDetect } from '@fable/hooks'
import { isWebViewRoute } from './webview/utils'

export const App = () => {
  const location = useLocation()
  const { getGeoIpCountry } = useActions()
  const { isVerifying } = useTypedSelector(({ auth }) => auth)
  const { geoIpBlocked } = useTypedSelector(({ geoIp }) => geoIp)
  const { fonts } = useTheme()
  const active = !isVerifying ? 'active' : ''
  const vertExpEnabled = useVertExpEnabled()
  const { isMobile, inAppBrowser } = useDeviceDetect()

  useLoadAnalytics()
  useSingular()
  useLoadCart()
  useConsent()
  useAuthProviderRedirect()
  useCacheOwnedBooks()
  useLoadFeatureFlags()
  useProfileRedirect()

  useEffect(() => {
    injectGlobal`
      * {
        margin: 0;
        padding: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        -moz-osx-font-smoothing: grayscale;
      }

      body > * {
        font-family: ${fonts.body};
        font-weight: 400;
        font-style: normal;
        font-display: optional;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li {
        cursor: default;
      }

      ul {
        list-style-type: none;
      }

      a {
        text-decoration: none;
      }
  `
  }, [fonts.body])

  useEffect(() => {
    if (!getCookie(GEO_IP_COUNTRY_SET)) {
      getGeoIpCountry()
    }
  }, [getGeoIpCountry])

  // load typography
  useEffect(() => {
    WebFont.load({
      custom: {
        families: APP_FONTS,
      },
    })
  }, [])

  // NOTE: Temporary fix for removing careerClubJoinKey from localStorage.
  // Will be removed during the xstate checkout integration.
  useEffect(() => {
    const careerClubJoinKey = localStorage.getItem(CAREER_CLUB_JOIN_KEY)
    if (
      !isVerifying &&
      careerClubJoinKey &&
      !location.pathname.includes('checkout')
    ) {
      localStorage.removeItem(CAREER_CLUB_JOIN_KEY)
    }
  }, [location.pathname, isVerifying])

  /**
   * Some countries are blocked due to OFAC sanctions
   * See geoBlockSaga and relative store
   */
  if (geoIpBlocked && (!isMobile || !isWebViewRoute(location.pathname))) {
    return <GeoBlockErrorPage />
  }

  return (
    <Suspense fallback={<Loader type={LoaderType.fullscreen} />}>
      {isVerifying && (
        <div className="screen-height">
          <Loader type={LoaderType.fullscreen} />
        </div>
      )}
      <div data-testid="fable-web-app" className={`app ${active}`}>
        <Header />
        <main className="app-content">
          <Subheader />
          <AppRoutes />
        </main>
        <SuspenseWithErrorBoundary>
          <Modal />
          <VideoLightbox />
          <Notification />
          <Toast />
          {vertExpEnabled ? <Footer /> : <FooterLegacy />}
          {isMobile && inAppBrowser === 'tiktok' ? <TikTokOverlay /> : null}
        </SuspenseWithErrorBoundary>
      </div>
    </Suspense>
  )
}
