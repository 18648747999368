export const base = 'shelfie--'

const animClass = (id: string) => {
  const name = `${base}${id}`

  return {
    name,
    selector: `.${name}`,
  }
}

export const shelfieAnims = {
  editor: {
    header: animClass('editor-header'),
    footer: animClass('editor-footer'),
    title: animClass('editor-title'),
    summary: animClass('editor-summary'),
    shelf: animClass('editor-shelf'),
    shelfItem: animClass('editor-shelf-item'),
    tapTooltip: animClass('editor-tap-tooltip'),
    editTextTooltip: animClass('editor-edit-text-tooltip'),
    ep: {
      panel: animClass('editor-edit-panel'),
      trigger: animClass('editor-edit-panel-trigger'),
      bounds: animClass('editor-edit-panel-bounds'),
      container: animClass('editor-edit-panel-container'),
    },
  },
}
