import { getParameterByName } from '@fable/utils'
import { routes } from '../routes'
import { routes as socialRoutes } from 'app/social/routes'

export const platformParam = getParameterByName('platform')
export const isAndroid = !!platformParam && platformParam === 'android'

export const appendModeCors = (imageUrl: string): string => {
  return imageUrl.includes('?')
    ? `${imageUrl}&mode=cors`
    : `${imageUrl}?mode=cors`
}

export const isWebViewRoute = (pathname: string) => {
  const wvRoutes = Object.values(routes).map((r) => r)
  const isStats = pathname.includes(socialRoutes.stats)
  return isStats || wvRoutes.includes(pathname)
}
