import { SearchContextProvider } from 'state'
import TypographyTestPage from '../../app/marketing/pages/TypographyPreviewPage'
import { routes } from '../../app/marketing/routes'
import { lazyWithRetry } from '../../utils'
import { pageMetadata } from '../pageMetadata'
import { AppRouteProps } from '../types'
import { ClubsPageProvider } from 'app/marketing/context'

const ClubHomePage = lazyWithRetry(
  () => import('../../app/marketing/pages/club_home_page/ClubHomePage')
)
const ClubsPage = lazyWithRetry(
  () => import('../../app/marketing/pages/clubs_page/ClubsPage')
)
const PremiumClubsPage = lazyWithRetry(
  () => import('../../app/marketing/pages/premium_clubs/PremiumClubsPage')
)
const Support = lazyWithRetry(
  () => import('../../app/marketing/pages/support/Support')
)
const Company = lazyWithRetry(
  () => import('../../app/marketing/pages/company/Company')
)
const Download = lazyWithRetry(
  () => import('../../app/marketing/pages/download/Download')
)
const Press = lazyWithRetry(
  () => import('../../app/marketing/pages/press/Press')
)
const B2b = lazyWithRetry(() => import('../../app/marketing/pages/b2b/B2b'))
const Pride = lazyWithRetry(() => import('../../app/marketing/pages/Pride'))
const Privacy = lazyWithRetry(
  () => import('../../app/marketing/pages/privacy/Privacy')
)
const Security = lazyWithRetry(
  () => import('../../app/marketing/pages/security/Security')
)
const Terms = lazyWithRetry(
  () => import('../../app/marketing/pages/terms/Terms')
)
const CommunityGuidelines = lazyWithRetry(
  () =>
    import('../../app/marketing/pages/community_guidelines/CommunityGuidelines')
)
const EmployeeAndHrPrivacy = lazyWithRetry(
  () =>
    import('../../app/marketing/pages/employee_hr_privacy/EmployeeHrPrivacy')
)
const CopyrightPolicy = lazyWithRetry(
  () => import('../../app/marketing/pages/copyright_policy/CopyrightPolicy')
)
const MastercardTerms = lazyWithRetry(
  () => import('../../app/marketing/pages/terms/mastercard/MastercardTerms')
)
const Education = lazyWithRetry(
  () => import('../../app/marketing/pages/education/Education')
)

const PreorderLandingPage = lazyWithRetry(
  () =>
    import(
      '../../app/marketing/pages/preorder_landing_page/PreorderLandingPage'
    )
)

const HomeFeedLandingPage = lazyWithRetry(
  () =>
    import(
      '../../app/marketing/pages/home_feed_landing_page/HomeFeedLandingPage'
    )
)

const ReaderLandingPage = lazyWithRetry(
  () =>
    import('../../app/marketing/pages/reader_landing_page/ReaderLandingPage')
)

const BuddyLandingPage = lazyWithRetry(
  () => import('../../app/marketing/pages/buddy_landing_page/BuddyLandingPage')
)

const HomePage = lazyWithRetry(
  () => import('../../components/home_page/HomePage')
)

const GRLandingPage = lazyWithRetry(
  () => import('../../app/marketing/pages/gr_landing_page/GRLandingPage')
)

const ShelfieLandingPage = lazyWithRetry(
  () =>
    import('../../app/marketing/pages/shelfie_landing_page/ShelfieLandingPage')
)

const EoyWrapLandingPage = lazyWithRetry(
  () =>
    import('../../app/marketing/pages/eoy_wrap_landing_page/EoyWrapLandingPage')
)

export const useMarketingRoutes = (): AppRouteProps[] => {
  const allRoutes: AppRouteProps[] = [
    {
      path: '/typography-preview',
      element: <TypographyTestPage />,
      footer: { hidden: true },
    },
    {
      path: routes.clubFeatures,
      element: <ClubHomePage />,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
    },
    {
      path: routes.clubsPage,
      element: (
        <ClubsPageProvider>
          <SearchContextProvider allowFilters={false}>
            <ClubsPage />
          </SearchContextProvider>
        </ClubsPageProvider>
      ),
      head: pageMetadata.main,
      header: {
        fillSpace: false,
        hidden: false,
      },
    },
    {
      path: routes.premiumClubs,
      element: <PremiumClubsPage />,
      head: pageMetadata.premiumClubs,
    },
    {
      path: routes.company,
      element: <Company />,
      head: pageMetadata.main,
    },
    {
      path: routes.support,
      element: <Support />,
      head: pageMetadata.support,
    },
    {
      path: routes.terms,
      element: <Terms variation="web" />,
      head: pageMetadata.terms,
    },
    {
      path: routes.termsIos,
      element: <Terms variation="ios" />,
      footer: { hidden: true },
      header: {
        hidden: true,
      },
      head: pageMetadata.termsIos,
    },
    {
      path: routes.privacy,
      element: <Privacy variation="web" />,
      head: pageMetadata.privacy,
    },
    {
      path: routes.privacyIos,
      element: <Privacy variation="ios" />,
      footer: { hidden: true },
      header: {
        hidden: true,
      },
      head: pageMetadata.privacyIos,
    },
    {
      path: routes.security,
      element: <Security />,
      head: pageMetadata.security,
    },
    {
      path: routes.employeeAndHrPrivacy,
      element: <EmployeeAndHrPrivacy />,
      head: pageMetadata.employeeAndHrPrivacy,
    },
    {
      path: routes.communityGuidelines,
      element: <CommunityGuidelines />,
      head: pageMetadata.communityGuidelines,
    },
    {
      path: routes.copyrightPolicy,
      element: <CopyrightPolicy />,
      head: pageMetadata.copyrightPolicy,
    },
    {
      path: routes.download,
      element: <Download />,
      head: pageMetadata.download,
      footer: { hidden: true },
    },
    {
      path: routes.press,
      element: <Press />,
      head: pageMetadata.press,
    },
    {
      path: routes.b2b,
      element: <B2b />,
      head: pageMetadata.forBusiness,
    },
    {
      path: routes.pride,
      element: <Pride />,
      head: pageMetadata.pride,
    },
    {
      path: routes.termsMastercard,
      element: <MastercardTerms />,
      head: pageMetadata.mastercardTerms,
    },
    {
      path: routes.education,
      element: <Education />,
    },
    {
      path: '/preorder/forget-me-not-by-julie-soto',
      element: <PreorderLandingPage />,
      footer: { hidden: true },
      header: {
        hidden: true,
      },
    },
    {
      path: routes.homeFeedLp,
      element: <HomeFeedLandingPage />,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
    },
    {
      path: routes.readerLp,
      element: <ReaderLandingPage />,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
    },
    {
      path: routes.buddyLp,
      element: <BuddyLandingPage />,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
    },
    {
      path: routes.goodreadsLp,
      element: <GRLandingPage />,
      head: pageMetadata.goodreadsLp,
      header: { hidden: true },
    },
    {
      path: routes.meetcute,
      element: <GRLandingPage />,
      head: pageMetadata.goodreadsLp,
      header: { hidden: true },
    },
    {
      path: routes.shelfieLp,
      element: <ShelfieLandingPage />,
      head: pageMetadata.shelfie,
      header: { hidden: true },
      deprecated: true,
    },
    {
      path: routes.eoyWrapLp,
      element: <EoyWrapLandingPage />,
      header: { hidden: true },
      deprecated: true,
    },
    {
      path: routes.home,
      element: <HomePage />,
      head: pageMetadata.main,
      header: {
        fillSpace: false,
        hidden: false,
        theme: 'secondary',
      },
    },
  ]

  return allRoutes
}
